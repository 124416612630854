export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyDp1mcKlv9ey2wHI_vLU0wMJgIVqcGlpkI",
    authDomain: "singleyalies.com",
    projectId: "single-yalies",
    storageBucket: "single-yalies.appspot.com",
    messagingSenderId: "579081904292",
    appId: "1:579081904292:web:6dc71ce7b4d0ad77174631",
    measurementId: "G-4M7MQPVE60",
};

export const MOBILE_WIDTH = 1000;

export const RELATIONSHIP_STATUSES = {
    "hidden": {
        buttonLabel: "Don't show a status",
        renderDisplayText: (firstName) => (
            <span>
                {firstName} has <span className="gradient_text">hidden their status.</span> You can always reach out to them directly.
            </span>
        ),
    },
    "unknown": {
        buttonLabel: "Status is unknown",
        renderDisplayText: (firstName) => (
            <span>
                <span className="gradient_text">We don't know</span> whether {firstName} is looking for a relationship right now.
                But, we've sent them an <b>anonymous</b> email asking them to let us know.
            </span>
        ),
    },
    "taken": {
        buttonLabel: "In a relationship",
        renderDisplayText: (firstName) => (
            <span>{firstName} is <span className="gradient_text">already in a relationship</span> right now.</span>
        ),
    },
    "not-looking": {
        buttonLabel: "Not looking for a relationship",
        renderDisplayText: (firstName) => (
            <span>{firstName} is <span className="gradient_text">not looking for a relationship</span> right now.</span>
        ),
    },
    "casual": {
        buttonLabel: "Looking for something casual",
        renderDisplayText: (firstName) => (
            <span>{firstName} is <span className="gradient_text">looking for something casual</span> right now.</span>
        ),
    },
    "committed": {
        buttonLabel: "Looking for a committed relationship",
        renderDisplayText: (firstName) => (
            <span>{firstName} is <span className="gradient_text">looking for a committed relationship</span> right now.</span>
        ),
    },
    "passive": {
        buttonLabel: "Not actively searching, but would be open to dating",
        renderDisplayText: (firstName) => (
            <span>{firstName} is not actively searching, but <span className="gradient_text">would be open to dating</span> right now.</span>
        ),
    },
};

export const MATCHMAKER_MAX_SELECTIONS = 3;
export const MATCHMAKER_CURRENT_ACTIVE_ROUND = "";
export const MATCHMAKER_PROMO_BANNER_ENABLED = false;
