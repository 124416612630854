import React, { useState } from "react";
import css from "./styles/StalkiesPage.module.scss";
import Button from "./Button";
import { faHand, faSearch } from "@fortawesome/free-solid-svg-icons";
import Navbar from "./Navbar";
import Input from "./Input";
import { httpsCallable } from "firebase/functions";
import { useFirebase } from "../contexts/firebaseContext";
import { logEvent } from "firebase/analytics";

function StalkiesPage() {
    const [searchText, setSearchText] = useState("");
    const [error, setError] = useState("");
    const [blurb, setBlurb] = useState("");
    const [name, setName] = useState("");
    const [showCreepy, setShowCreepy] = useState(false);
    const [progress, setProgress] = useState(0);
    const { functions, analytics } = useFirebase();

    const performSearch = async (e) => {
        e.preventDefault();
        setBlurb("");
        setName("");
        setShowCreepy(false);
        const interval = startProgressBarFill();
        if(!searchText) return;
        const stalkiesFn = httpsCallable(functions, "stalkies");

        let res;
        try {
            res = await stalkiesFn({ query: searchText });
        } catch(e) {
            console.error(e);
            setError(e.message);
            clearInterval(interval);
            setProgress(0);
            return;
        }
        const data = res.data;
        setBlurb(data.blurb);
        setName(data.name);

        logEvent(analytics, "stalkies_search", {
            query: searchText,
        });
    }

    const startProgressBarFill = () => {
        let progress = 0;
        const interval = setInterval(() => {
            const newProgress = ++progress;
            if(newProgress >= 100) {
                clearInterval(interval);
                setProgress(0);
            } else {
                setProgress(newProgress);
            }
        }, process.env.NODE_ENV === "development" ? 30 : 300);
        return interval;
    }

    const progressBar = progress !== 0 && (
        <>
            <div id={css.progress_bar}>
                <div id={css.progress_bar_filler} style={{width: `${progress}%`}} />
            </div>
            <p id={css.progress}>{progress}%</p>
        </>
    );

    const form = progress === 0 && (
        <form onSubmit={performSearch} id={css.search_bar}>
            <Input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Peter Salovey"
                jumbo
            />
            <Button
                icon={faSearch}
                onClick={performSearch}
                jumbo
                disabled={!searchText}
            />
        </form>
    );

    return (
        <>
            <Navbar />
            <div id={css.main_content} className="rails">
                <h1 id={css.title}>Stalkies.io</h1>
                {form}
                <p className={css.error}>{error}</p>
                {progressBar}
                { blurb && !showCreepy && progress === 0 && (
                    <div id={css.result}>
                        <h3>All we know about {name}</h3>
                        <p>{blurb}</p>
                        <Button secondary icon={faHand} onClick={() => setShowCreepy(true)}>
                            I think this is creepy
                        </Button>
                    </div>
                )}
                { showCreepy && (
                    <div id={css.result}>
                    <h3>Protect your online data!</h3>
                    <p>
                        All of this information was found online by an AI agent. The only things we told the agent were their full name and their hometown—which, by the way, is public on Yale's directory.
                    </p>
                    <p>
                        As AI grows exponentially more powerful, it will be more necessary than ever to protect your online data.
                    </p>
                    <p>
                        Next time you post something online, ask yourself whether you really want other people to be able to find it.
                    </p>
                </div>
                )}
            </div>
        </>
    );
}

export default StalkiesPage;
