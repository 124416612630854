import { Link } from "react-router-dom";
import css from "./styles/Navbar.module.scss";
import React, { useCallback } from "react";
import Button from "./Button";
import { faRightToBracket, faSignOut } from "@fortawesome/free-solid-svg-icons";
import AuthButton from "./AuthButton";
import { useFirebase } from "../contexts/firebaseContext";
import { getAuth, signOut } from "firebase/auth";

function Navbar() {
    const { user, auth } = useFirebase();
    
    const signOutUser = useCallback(async () => {
        await signOut(auth);
        window.location.href = "/";
    }, [auth]);

    const onSignIn = () => {
        if(window.location.pathname === "/stalkies") {
            window.location.href = "/stalkies";
            return;
        }
        window.location.href = "/search";
    }
    
    const authButton = user ?
        (
            <Button icon={faSignOut} onClick={signOutUser}>Sign out</Button>
        ) :
        (
            <AuthButton
                icon={faRightToBracket}
                onSignIn={onSignIn}
            >
                Log in
            </AuthButton>
        );

    return (
        <div id={css.navbar}>
            <div id={css.flex_left}>
                <Link to="/" class="nostyle">
                    <p id={css.name} className={css.navbar_link}>Single Yalies</p>
                </Link>
            </div>
            <div id={css.flex_right}>
                {
                    user && (
                        <>
                            <Link to="/stats" class="nostyle">
                                <p className={css.navbar_link}>Stats</p>
                            </Link>
                            <Link to="/profile" class="nostyle">
                                <p className={css.navbar_link}>Edit profile</p>
                            </Link>
                        </>
                    )
                }
                {authButton}
            </div>
            <div id={css.gradient_effect} />
        </div>
    );
}

export default Navbar;
