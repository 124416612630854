import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "./Button";
import { GoogleAuthProvider, browserLocalPersistence, getAuth, getRedirectResult, setPersistence, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { useFirebase } from "../contexts/firebaseContext";
import { logEvent } from "firebase/analytics";

function AuthButton({ large, primary, secondary, icon, iconRight, children, onSignIn }) {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { auth, user, analytics } = useFirebase();

    const provider = useMemo(() => {
        const ret = new GoogleAuthProvider();;
        ret.setCustomParameters({
            hosted_domain: "yale.edu"
        });
        return ret;
    }, []);

    const onClick = useCallback(async () => {
        if(!onSignIn) return;
        setLoading(true);

        if(user) { // Skip if already signed in
            onSignIn(user);
            return;
        }

        try {
            await setPersistence(auth, browserLocalPersistence);
        } catch(e) {
            setError("An unknown error has occurred.");
            return;
        }

        let result;
        try {
            result = await signInWithPopup(auth, provider);
        } catch(e) {
            console.error(e);
            if(e.code === "auth/cancelled-popup-request" || e.code === "auth/popup-closed-by-user"){
                this.setState({loading: false});
                return;
            }
            if(e.code === "auth/popup-blocked"){
                if(process.env.NODE_ENV === "development") {
                    console.error("Your browser blocked the popup; we would normally attempt to sign in with redirect, but this is not supported in development. See: https://firebase.google.com/docs/auth/web/redirect-best-practices#update-authdomain");
                    return;
                }
                signInWithRedirect(auth, provider);
                return;
            }
            setError("We could not sign you in due to an unknown error. Please try again.");
            return;
        }
        console.log(`Signed in as ${result.user.displayName} (${result.user.email})`);
        if(result.user.email.split("@").pop().toLowerCase() !== "yale.edu") {
            window.location.href = "/disallowed";
            return;
        }
        logEvent(analytics, "sign_in");
        onSignIn(result.user);
    }, [analytics, auth, onSignIn, provider, user]);

    const redirectCallback = useCallback(async () => {
        let res;
        try {
            res = await getRedirectResult(auth);
        } catch(e) {
            setError("We could not sign you in due to an unknown error. Please try again.");
            return;
        }
        if(!res) return;

        console.log(`Signed in as ${res.user.displayName} (${res.user.email})`);
        if(res.user.email.split("@").pop().toLowerCase() !== "yale.edu") {
            window.location.href = "/disallowed";
            return;
        }
        logEvent(analytics, "sign_in");
        onSignIn(res.user);
    }, [analytics, auth, onSignIn]);

    useEffect(() => {
        redirectCallback();
    }, [redirectCallback]);

    return (
        <Button
            large={large}
            primary={primary}
            secondary={secondary}
            icon={icon}
            iconRight={iconRight}
            onClick={onClick}
            disabled={isLoading}
        >{children}</Button>
    );
}

export default AuthButton;
